import template from './order-types-setup.html';

class OrderTypesPageViewModel 
{
	constructor (page) 
	{
		this.order_types = ko.observable({});
		this.selected_order_type = ko.observable(null);
	}

	async update ()
	{
		this.order_types(await Grape.cache.fetch('OrderTypes'));
	}
}

class OrderTypesPage 
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new OrderTypesPageViewModel(this);
		document.title = 'SystemSetup: Order Types';
	}

	async updateData ()
	{
		return this.viewModel.update();
	}
}

export default {                
	route: '[/]ui/orders/order-types/setup',
	page_class: OrderTypesPage,
	template: template              
}
