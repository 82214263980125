
import OrderTypesSetup from './order-types-setup/order-types-setup.js';
import OrderCapture from './order-capture/order-capture.js';
import OrdersList from './orders-list/orders-list.js';

export default [
	OrderTypesSetup,
	OrderCapture,
	OrdersList
];

